import React from "react";
import { Box, Button, Grid, Stack, styled, Typography } from "@mui/material";
import imageActionPro from "resources/images/home/action-pro.png";
import { ReactComponent as Calendar } from "resources/images/svg/home/calendar.svg";
import SectionWrapper from "layout/SectionWrapper";
import { useIsMobile } from "../../hooks/responsive";

const EventBox = styled(Box)(() => ({
	width: "fit-content",
	padding: "20px",
	backgroundColor: "#1B1843",
	borderRadius: 20,
	position: "relative",
	"&::before": {
		content: "''",
		position: "absolute",
		inset: 0,
		borderRadius: 20,
		padding: 2,
		background:
			"linear-gradient(157.08deg, #943E9B 0%, #1564DC 26.5%, rgba(0, 0, 0, 0) 53%, #943E9B 76.5%, #1564DC 100%)",
		"-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
		"-webkit-mask-composite": "xor",
		maskComposite: "exclude",
	},
}));

const EVENTS = [
	{
		date: "Septembre 2024 à Avril 2025",
		title: "Groupe 4",
		link: "https://www.inrees.com/ev/Groupes/supervisionG4-autour-de-l-extraordinaire",
		description:
			"Ce groupe est destiné aux professionnels sensibilisés à l'invisible qui souhaitent questionner leurs pratiques, les développer et améliorer l’accompagnement…",
	},
	{
		date: "Janvier 2025 à Novembre 2025",
		title: "Groupe 5",
		link: "https://www.inrees.com/ev/Groupes/supervisionG5-autour-de-l-extraordinaire",
		description:
			"Ce groupe est destiné aux professionnels sensibilisés à l'invisible qui souhaitent questionner leurs pratiques, les développer et améliorer l’accompagnement…",
	},
];

const openInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

const HomeActionsPro = () => {
	const isMobile = useIsMobile();

	return (
		<SectionWrapper backgroundColor={"rgba(255,255,255,0.06)"}>
			<Box>
				<Typography
					color={"#FBF0E5"}
					fontFamily={"Yesevaone"}
					fontSize={40}
					textAlign={"center"}
					lineHeight={1.3}
				>
					Nos actions d’intérêt général
				</Typography>
				<Box mt={6} display={"flex"} alignItems={"center"} flexDirection={"column"}>
					<Box>
						<Grid container justifyContent={"center"} alignItems={"center"} spacing={3}>
							<Grid item md={6} xs={12} justifyContent={isMobile ? "center" : "start"} display={"flex"}>
								<img src={imageActionPro} style={{ maxWidth: "70%" }} />
							</Grid>
							<Grid item md={6} xs={12}>
								<Stack justifyContent={"center"} spacing={2}>
									<Typography
										sx={{
											background: "linear-gradient(90deg, #992FDD 0%, #1D73FF 100%)",
											"-webkit-background-clip": "text",
											"-webkit-text-fill-color": "transparent",
										}}
										fontFamily={"Branding Bold"}
										fontSize={24}
									>
										POUR LES PROFESSIONNELS : LA SUPERVISION
									</Typography>
									<Typography fontFamily={"Branding Medium"} color={"#FFFFFF"}>
										Depuis 2022, des groupes de supervision encadrés par les membres du réseau, 
										invitent leurs participants, à mener un travail réflexif sur l'accompagnement 
										des personnes ayant vécu des expériences extraordinaires.
										<br />
										<br />
										Lors des sessions, les échanges qui s’articulent autour de cas cliniques, 
										questionnent les concepts théoriques, les apports de la recherche sur 
										les états modifiés de conscience, les diagnostics différentiels, 
										l’analyse du transfert et contre-transfert, l’analyse des pratiques, 
										la déontologie et le cadre… Des indispensables pour accueillir l’extraordinaire !
									</Typography>
								</Stack>
							</Grid>
						</Grid>
						<Box width={"100%"} mt={5}>
							<Typography color={"#FBF0E5"} fontFamily={"Branding Bold"} fontSize={24}>
								Les prochains événements
							</Typography>
							<Grid container justifyContent={isMobile ? "center" : "space-between"} mt={3} spacing={3}>
								{EVENTS.map((event) => (
									<Grid item xs={10} md={12 / 2} sx={{}}>
										<EventBox>
											<Stack spacing={1}>
												<Box sx={{ display: "flex" }}>
													<Calendar />
													<Typography
														ml={2}
														fontFamily={"Branding SemiBold"}
														color={"#FFFFFF"}
														fontSize={18}
													>
														{event.date}
													</Typography>
												</Box>
												<Typography
													fontFamily={"Branding Bold"}
													color={"#FFFFFF"}
													fontSize={24}
													sx={{ marginTop: "20px !important" }}
												>
													{event.title}
												</Typography>
												<Typography
													fontFamily={"Branding Medium"}
													color={"#FFFFFF"}
													fontSize={15}
													lineHeight={1.2}
												>
													{event.description}
												</Typography>
												<Button
													variant="contained"
													sx={{
														backgroundColor: "#FBF0E5",
														borderRadius: 10,
														color: "#182B43",
														fontFamily: "Branding SemiBold",
														textTransform: "none",
														padding: "10px 20px",
														width: 150,
														marginTop: "30px !important",
													}}
													onClick={() => openInNewTab(event.link)}
													title="En savoir plus sur ce groupe"
												>
													En savoir plus
												</Button>
											</Stack>
										</EventBox>
									</Grid>
								))}
							</Grid>
						</Box>
					</Box>
				</Box>
			</Box>
		</SectionWrapper>
	);
};
export default HomeActionsPro;
