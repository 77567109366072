import React from "react";
import HomeIntro from "../components/holistiquePage/HomeIntro";
import { Box } from "@mui/material";
import HomeNewsLetter from "../components/holistiquePage/HomeNewsLetter";
import HomeActionsPro from "../components/holistiquePage/HomeActionsPro";
import HomeExperts from "../components/holistiquePage/HomeExperts";
import HomeInterest from "../components/holistiquePage/HomeInterest";
import useTabTitle from "../hooks/useTabTitle";

const Holistique = () => {
	useTabTitle({ customTitle: "Vers une approche holistique - Inexploré pro" });
	return (
		<Box sx={{ backgroundColor: "#09011E" }}>
			<HomeIntro />
			<HomeNewsLetter />
		</Box>
	);
};

export default Holistique;
