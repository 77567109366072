import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import Sebastien from "resources/images/home/experts/sebastien 1.png";
import { ReactComponent as Ellipse } from "resources/images/svg/home/expert-ellipse.svg";
import { ReactComponent as Plus } from "resources/images/svg/home/plus.svg";
import { ReactComponent as Arrow } from "resources/images/svg/common/arrow_bottom.svg";
import Isabelle from "resources/images/home/experts/isabelle.png";
import Isabelle2 from "resources/images/home/experts/isabelle2.png";
import Lydie from "resources/images/home/experts/lydie.png";
import SectionWrapper from "../../layout/SectionWrapper";
import { useIsMobile } from "../../hooks/responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css";
import HomeExpertCard from "./HomeExpertCard";

const Tag = styled(Box)(() => ({
	borderWidth: 2,
	borderStyle: "dotted",
	borderColor: "grey",
	borderImageSource: "linear-gradient(90deg, #992FDD 0%, #1D73FF 100%)",
	borderImageSlice: 1,
	width: "fit-content",
	padding: "5px 20px",
}));

const EventBox = styled(Box)(() => ({
	width: "fit-content",
	borderRadius: 20,
	position: "relative",
	"&::before": {
		content: "''",
		position: "absolute",
		inset: 0,
		borderRadius: 20,
		padding: 2,
		background:
			"linear-gradient(157.08deg, #943E9B 0%, #1564DC 26.5%, rgba(0, 0, 0, 0) 53%, #943E9B 76.5%, #1564DC 100%)",
		"-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
		"-webkit-mask-composite": "xor",
		maskComposite: "exclude",
	},
}));

const EXPERTS = [
	{ name: "Lydie Lancelot-Plaquet", description: "Psychothérapeute indépendante", image: Lydie },
	{ name: "Isabelle André", description: "Psychothérapeute indépendante", image: Isabelle },
	{ name: "Lydie Lancelot-Plaquet", description: "Psychothérapeute indépendante", image: Lydie },
	{ name: "Isabelle André", description: "Psychothérapeute indépendante", image: Isabelle },
	{ name: "Isabelle Bellin", description: "Journaliste scientifique", image: Isabelle2 },
	{ name: "Isabelle Bellin", description: "Journaliste scientifique", image: Isabelle2 },
	{ name: "Isabelle Bellin", description: "Journaliste scientifique", image: Isabelle2 },
];

const HomeExperts = () => {
	const isMobile = useIsMobile();
	const sliderRef = React.useRef(null);

	const handlePrev = React.useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
	}, []);

	const handleNext = React.useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
	}, []);

	return (
		<SectionWrapper backgroundColor={"rgba(255,255,255,0.03)"}>
			<Tag mb={4}>
				<Typography fontFamily={"Branding Medium"} color={"#FBF0E5"}>Professionnels de santé </Typography>
			</Tag>
			<Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap-reverse"}>
				<Box>
					<Typography color={"#FBF0E5"} fontFamily={"Branding Bold"} fontSize={35}>
						Un groupe d’experts
					</Typography>
					<Typography
						color={"rgba(251,240,229,0.7)"}
						fontFamily={"Branding SemiBold"}
						fontSize={16}
						maxWidth={800}
						mt={3}
					>
						Accompagné par Sébastien Lilli et la direction d’Inexploré, ce réseau est constitué d'un petit groupe de psychologues et de psychothérapeutes indépendants diplômés
						en psychologie clinique ou en thérapie avancée spécialisée. Ces professionnels ont développé une connaissance affinée
						des expériences extraordinaires rencontrées pendant l'existence et sont spécialisés dans l'accompagnement
						de ce type d'expériences et de vécu.{" "}
					</Typography>
				</Box>
				<EventBox>
					<Ellipse
						style={{
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: 0,
							width: 200,
							height: 200,
						}}
					/>
					<img src={Sebastien} height={244} style={{ zIndex: 2, position: "relative" }} />
				</EventBox>
			</Box>
			{isMobile && (
				<Box sx={{ position: "relative", width: "100%" }}>
					<Swiper
						ref={sliderRef}
						style={{ marginTop: 30 }}
						slidesPerView={1}
						onSlideChange={() => console.log("slide change")}
						onSwiper={(swiper) => console.log(swiper)}
						modules={[Navigation]}
					>
						{EXPERTS.map((expert) => (
							<SwiperSlide style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<HomeExpertCard expert={expert} />
							</SwiperSlide>
						))}
						<SwiperSlide>
							<ButtonPlus />
						</SwiperSlide>
					</Swiper>
					<Box
						onClick={handlePrev}
						sx={{
							backgroundColor: "rgba(255,255,255,0.36)",
							position: "absolute",
							top: "50%",
							left: 0,
							transform: "translateY(-50%)",
							borderRadius: 31,
							zIndex: 3,
							height: 60,
							width: 60,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Arrow style={{ rotate: "90deg" }} width={50} height={50} />
					</Box>
					<Box
						onClick={handleNext}
						sx={{
							backgroundColor: "rgba(255,255,255,0.36)",
							position: "absolute",
							top: "50%",
							right: 0,
							transform: "translateY(-50%)",
							borderRadius: 31,
							zIndex: 3,
							height: 60,
							width: 60,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Arrow style={{ rotate: "-90deg" }} width={50} height={50} />
					</Box>
				</Box>
			)}
			{!isMobile && (
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					sx={{ flexWrap: "wrap", gap: 10 }}
					mt={10}
				>
					{EXPERTS.map((expert) => (
						<HomeExpertCard expert={expert} />
					))}
					<ButtonPlus />
				</Box>
			)}
		</SectionWrapper>
	);
};

export default HomeExperts;

const ButtonPlus = () => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				height: 221,
				justifyContent: "flex-end",
				flexDirection: "column",
			}}
		>
			<EventBox
				sx={{
					width: 125,
					height: 125,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "100%",
					backgroundColor: "rgba(255,255,255,0.05)",
					cursor: "pointer",
					"&::before": {
						borderRadius: "100%",
					},
				}}
			>
				<Plus />
			</EventBox>
			<Typography mt={2} fontFamily={"Branding SemiBold"} fontSize={15} color={"#FBF0E5"} textAlign={"center"}>
				Postuler pour faire partie de nos experts
			</Typography>
		</Box>
	);
};
