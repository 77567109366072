import React from "react";
import { useGetUniversQuery } from "store/api/app";
import { Box, Fade, styled, Typography } from "@mui/material";
import earth from "resources/images/svg/header/earth.svg";
import chevron_down from "resources/images/svg/header/dropdown_arrow.svg";
import UniversesList from "./UniversesList";
import { ReactComponent as Earth } from "resources/images/svg/header/earth.svg";

const BoxUniversContainer = styled(Box)(({ theme }) => {
	return {
		height: 50,
		width: 190,
		background: "#A5D0C9",
		borderRadius: 24,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		paddingLeft: 10,
		paddingRight: 10,
		transition: theme.transitions.create(["box-shadow"]),
		cursor: "pointer",
		position: "relative",
		"&.isActive": {
			boxShadow: "0 0 15px 0 #EE7A91",
			"& .univers-chevron": {
				transform: "rotate(180deg)",
			},
		},
		"& .univers-chevron": {
			transition: theme.transitions.create(["transform"]),
		},
	};
});

const LogoContainer = styled(Box)(({ theme }) => {
	return {
		width: 30,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};
});

const EarthStyle = styled("img")(({ theme }) => {
	return {
		width: 22,
	};
});

const ChevronStyle = styled("img")(({ theme }) => {
	return {
		width: 16,
	};
});

const Universe = ({ isMobile = false }) => {
	const { data: univers } = useGetUniversQuery();
	const [isOpen, setIsOpen] = React.useState(false);

	if (isMobile) {
		return (
			<Fade in={!!univers}>
				<Box
					onMouseEnter={() => setIsOpen(true)}
					onMouseLeave={() => setIsOpen(false)}
					sx={{ position: "relative" }}
				>
					<BoxUniversContainer
						sx={{ width: "auto", background: "transparent" }}
						className={isOpen ? "isActive" : ""}
					>
						<LogoContainer>
							<Earth width={22} height={22} color={"white"} />
						</LogoContainer>
					</BoxUniversContainer>
					<UniversesList isMobile={isMobile} isOpen={isOpen} inSubMenu={true} />
				</Box>
			</Fade>
		);
	}

	return (
		<Fade in={!!univers}>
			<Box
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
				sx={{ position: "relative" }}
			>
				<BoxUniversContainer className={isOpen ? "isActive" : ""}>
					<LogoContainer>
						<EarthStyle src={earth} alt={"earth-logo"} style={{ color: "#182B43" }} />
					</LogoContainer>
					<Typography fontFamily={"Branding Medium"} fontWeight={"600"}>
						Nos univers
					</Typography>
					<LogoContainer>
						<ChevronStyle className={"univers-chevron"} src={chevron_down} alt={"chevron-logo"} />
					</LogoContainer>
				</BoxUniversContainer>
				<UniversesList isOpen={isOpen} inSubMenu={true} />
			</Box>
		</Fade>
	);
};

export default Universe;
