import React from "react";
import { Box, Button, Grid, styled, Typography } from "@mui/material";
import { ReactComponent as InexploreProLogo } from "resources/images/svg/common/inexplore_pro.svg";
import astronaut from "resources/images/main/astronaut.jpg";
import { ReactComponent as Building } from "resources/images/svg/main/building.svg";
import { ReactComponent as Infinity } from "resources/images/svg/main/infinity.svg";
import { ReactComponent as SuiteCase } from "resources/images/svg/main/suitecase-medical.svg";
import { Link, useNavigate } from "react-router-dom";
import { useIsMobile } from "../../hooks/responsive";
import useTabTitle from "../../hooks/useTabTitle";

const LeftGradient = styled(Box)(() => {
	return {
		background:
			"linear-gradient(89.98deg, #0F0037 1.06%, #0F0037 62.92%, rgba(15, 0, 55, 0) 99.98%, rgba(15, 0, 55, 0.2) 99.98%)",
		position: "absolute",
		bottom: 0,
		left: 0,
		top: 0,
		right: 50,
		zIndex: 3,
	};
});
const BottomGradient = styled(Box)(() => {
	return {
		background: "linear-gradient(0deg, #09011E 19.09%, rgba(9, 1, 30, 0) 74.34%);\n",
		position: "absolute",
		bottom: -15,
		left: 0,
		right: 0,
		zIndex: 4,
		height: 100,
	};
});

const EventBox = styled(Box)(() => ({
	width: "fit-content",
	padding: "20px",
	backgroundColor: "#1B1843",
	borderRadius: 20,
	position: "relative",
	height: "100%",
	"&::before": {
		content: "''",
		position: "absolute",
		inset: 0,
		borderRadius: 20,
		padding: 2,
		background:
			"linear-gradient(157.08deg, #943E9B 0%, #1564DC 26.5%, rgba(0, 0, 0, 0) 53%, #943E9B 76.5%, #1564DC 100%)",
		"-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
		"-webkit-mask-composite": "xor",
		maskComposite: "exclude",
	},
}));

const Tag = styled(Box)(() => ({
	borderWidth: 2,
	borderStyle: "dotted",
	borderColor: "grey",
	borderImageSource: "linear-gradient(90deg, #992FDD 0%, #1D73FF 100%)",
	borderImageSlice: 1,
	width: "fit-content",
	padding: "5px 20px",
}));

const CARDS = [
	{
		logo: <SuiteCase height={63} />,
		category: "Professionnels de santé",
		title: "Accueillir l’extraordinaire",
		link: "/Accueillir-extraordinaire",
		description:
			"Le réseau des professionnels de santé a été créé dans la perspective de développer une approche thérapeutique qui intègre et reçoit les expériences extraordinaires de celui ou celle qui les vit…",
	},
	{
		logo: <Infinity height={63} />,
		category: "Praticiens & soignants",
		title: "Vers une approche holistique ",
		link: "/Approche-holistique",
		description:
			"Au cœur de l’extraordinaire, les soins intégratifs, en tant qu'approche holistique, visent à favoriser le bien-être des individus en tenant compte que de l’expérience multidimensionnelle de chacun…",
	},
];

const MainHeader = () => {
	useTabTitle({ customTitle: "Inexploré pro" });
	const [imageWidth, setImageWidth] = React.useState(0);
	const navigate = useNavigate();
	const isMobile = useIsMobile();

	const onLoad = (event) => {
		setImageWidth(event.target.offsetWidth);
	};

	return (
		<Box>
			<Box
				sx={{
					position: "relative",
					paddingTop: isMobile ? 5 : 10,
					paddingLeft: isMobile ? 3 : 6,
					paddingRight: isMobile ? 3 : 0,
				}}
			>
				<Box sx={{ position: "relative", zIndex: 4, minHeight: 550 }}>
					<InexploreProLogo width={isMobile ? 200 : 350} height={60} />
					<Typography
						fontFamily={"Branding Medium"}
						color={"#FBF0E5"}
						fontSize={16}
						maxWidth={600}
						mt={3}
						mb={8}
					>
						Dans un monde en profonde mutation et alors que la réalité semble plus vaste que celle que nous expérimentons,
						s’ouvrir aux dimensions visibles et invisibles est essentiel pour une expérience de vie riche de sens et plurielle.
						<br />
						<br />
						Le monde professionnel, qu’il concerne les métiers du soin ou d’accompagnement de la personne, 
						les projets d’envergure des entreprises privées innovantes, l’entreprenariat et ses prises de conscience individuelles, 
						semble tout aussi concerné par ces interpénétrations de l’invisible dans le visible.
						<br />
						<br />
						C’est pourquoi, pour vous accompagner, professionnels de tout secteur, à intégrer l’invisible dans vos pratiques,
						vos réflexions et vos actions, Inexploré vous propose des espaces dédiés par projets, domaines d’activité, approches et professions.
					</Typography>
				</Box>
				<BottomGradient />
				{!isMobile && <LeftGradient sx={{ right: imageWidth - 463 }} />}
				<img
					onLoad={onLoad}
					src={astronaut}
					style={{
						position: "absolute",
						right: 0,
						top: 0,
						bottom: 0,
						height: "100%",
						opacity: isMobile ? 0.5 : 1,
					}}
				/>
			</Box>
			<Box sx={{ top: -120, position: "relative", zIndex: 4 }}>
				<Grid container spacing={3} pl={isMobile ? 0 : 6} justifyContent={isMobile ? "center" : "none"}>
					{CARDS.map((card) => (
						<Grid item md={4} xs={11}>
							<EventBox display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
								<Box mb={1}>{card.logo}</Box>
								<Tag>
									<Typography fontFamily={"Branding Medium"} color={"#FBF0E5"}>
										{card.category}
									</Typography>
								</Tag>
								<Typography
									fontFamily={"Yesevaone"}
									fontSize={30}
									color={"#FBF0E5"}
									lineHeight={1.2}
									mt={2}
									mb={2}
								>
									{card.title}
								</Typography>
								<Typography fontFamily={"Branding Medium"} fontSize={18} color={"#FBF0E5"} mb={3}>
									{card.description}
								</Typography>
								<Link to={card.link}>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "#FBF0E5",
											borderRadius: 10,
											color: "#182B43",
											fontFamily: "Branding SemiBold",
											textTransform: "none",
											padding: "10px 20px",
											width: "100%",
										}}
									>
										En savoir plus
									</Button>
								</Link>
							</EventBox>
						</Grid>
					))}
					<Grid item md={2.7} xs={8}>
						<EventBox sx={{ backgroundColor: "#09011EE5" }}>
							<Box mb={2}>
								<Building height={63} />
							</Box>
							<Box sx={{ border: "1px solid #FBF0E5", padding: "5px 20px", width: "fit-content" }}>
								<Typography fontFamily={"Branding Medium"} color={"#FBF0E5"}>
									Dirigeants & managers
								</Typography>
							</Box>
							<Typography
								fontFamily={"Yesevaone"}
								fontSize={30}
								color={"#FBF0E5"}
								lineHeight={1.2}
								mt={2}
								mb={2}
							>
								Oser l’extraordinaire en entreprise 
							</Typography>
							<Typography fontFamily={"Branding Medium"} fontSize={18} color={"#FBF0E5"}>
								Prochainement...
							</Typography>
						</EventBox>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default MainHeader;
