import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import Sebastien from "resources/images/home/experts/sebastien 1.png";
import { ReactComponent as Ellipse } from "resources/images/svg/home/expert-ellipse.svg";
import { ReactComponent as Plus } from "resources/images/svg/home/plus.svg";
import { ReactComponent as Arrow } from "resources/images/svg/common/arrow_bottom.svg";
import Isabelle from "resources/images/home/experts/isabelle.png";
import Isabelle2 from "resources/images/home/experts/isabelle2.png";
import Lydie from "resources/images/home/experts/lydie.png";
import SectionWrapper from "../../layout/SectionWrapper";
import { useIsMobile } from "../../hooks/responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css";
import HomeExpertCard from "./HomeExpertCard";

import { useGetReseauTeamsQuery } from "store/api/app";

const Tag = styled(Box)(() => ({
	borderWidth: 2,
	borderStyle: "dotted",
	borderColor: "grey",
	borderImageSource: "linear-gradient(90deg, #992FDD 0%, #1D73FF 100%)",
	borderImageSlice: 1,
	width: "fit-content",
	padding: "5px 20px",
}));

const EventBox = styled(Box)(() => ({
	width: "fit-content",
	borderRadius: 20,
	position: "relative",
	"&::before": {
		content: "''",
		position: "absolute",
		inset: 0,
		borderRadius: 20,
		padding: 2,
		background:
			"linear-gradient(157.08deg, #943E9B 0%, #1564DC 26.5%, rgba(0, 0, 0, 0) 53%, #943E9B 76.5%, #1564DC 100%)",
		"-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
		"-webkit-mask-composite": "xor",
		maskComposite: "exclude",
	},
}));

const EXPERTS = [
	{ name: "Lydie Lancelot-Plaquet", description: "Psychothérapeute indépendante", image: Lydie },
	{ name: "Isabelle André", description: "Psychothérapeute indépendante", image: Isabelle },
	{ name: "Lydie Lancelot-Plaquet", description: "Psychothérapeute indépendante", image: Lydie },
	{ name: "Isabelle André", description: "Psychothérapeute indépendante", image: Isabelle },
	{ name: "Isabelle Bellin", description: "Journaliste scientifique", image: Isabelle2 },
	{ name: "Isabelle Bellin", description: "Journaliste scientifique", image: Isabelle2 },
	{ name: "Isabelle Bellin", description: "Journaliste scientifique", image: Isabelle2 },
];
const seb = { nom: "", prenom: "", profession: "Sous la direction de Sébastien Lilli", png: Sebastien };

const HomeExperts = () => {
	const isMobile = useIsMobile();
	const sliderRef = React.useRef(null);
	const [teamExperts, setTeamExperts] = React.useState([]);

	const handlePrev = React.useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
	}, []);

	const handleNext = React.useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
	}, []);

	const { data, isFetching } = useGetReseauTeamsQuery();
	
	React.useEffect(() => {
		// TODO : call /footer in the API with a fetch or a RTK Query.
		if (data) {
			setTeamExperts(Array.from(data?.infos?.reseau));
		}
	}, [data]);

	return (
		<SectionWrapper backgroundColor={"rgba(255,255,255,0.03)"}>
			<Tag mb={4}>
				<Typography fontFamily={"Branding Medium"} color={"#FBF0E5"}>Professionnels de santé </Typography>
			</Tag>
			<Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap-reverse"}>
				<Box>
					<Typography color={"#FBF0E5"} fontFamily={"Branding Bold"} fontSize={35}>
						Les référents du réseau
					</Typography>
					<Typography
						color={"rgba(251,240,229,0.7)"}
						fontFamily={"Branding SemiBold"}
						fontSize={16}
						maxWidth={800}
						mt={3}
					>
						Le réseau est constitué d’un petit groupe de psychologues et de psychothérapeutes indépendants diplômées en thérapie avancée spécialisée. 
						Ils, elles ont développé une connaissance affinée des expériences extraordinaires que certains peuvent rencontrer 
						au cours de leur existence et sont spécialisés dans l’accompagnement, et l’écoute de ce type de vécu. 
						<br/>Ensemble, ils, elles s’épaulent pour répondre aux messages adressés sur la plateforme de messagerie 
						dédiée aux récits et témoignages relatant ces expériences, ils échangent également lors des groupes de supervision, 
						avec d’autres professionnels.
{" "}
					</Typography>
				</Box>
				<EventBox>
					<Ellipse
						style={{
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: 0,
							width: 200,
							height: 200,
						}}
					/>

			<Box
				px={2}
				sx={{
					position: "absolute",
					bottom: -50,
					zIndex: 5,
					backgroundColor: "rgba(0,0,0,0.1)"
				}}
			>
				<Typography color={"#FBF0E5"} fontFamily={"Branding SemiBold"} textAlign={"center"} fontSize={16}>
				{seb.profession}
				</Typography>
				<Typography
					color={"rgba(251,240,229,0.5)"}
					fontFamily={"Branding SemiBold"}
					fontSize={14}
					textAlign={"center"}
				>
				</Typography>
			</Box>
					<img src={Sebastien} height={244} style={{ zIndex: 2, position: "relative" }} />
				</EventBox>
			</Box>
			{isMobile && (
				<Box sx={{ position: "relative", width: "100%" }}>
					{teamExperts !== null && (<Swiper
						ref={sliderRef}
						style={{ marginTop: 30 }}
						slidesPerView={1}
						onSlideChange={() => console.log("slide change")}
						onSwiper={(swiper) => console.log(swiper)}
						modules={[Navigation]}
					>
						{teamExperts.map((expert) => (
							<SwiperSlide style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<HomeExpertCard expert={expert} />
							</SwiperSlide>
						))}
						<SwiperSlide>
							<ButtonPlus />
						</SwiperSlide>
					</Swiper>)}
					<Box
						onClick={handlePrev}
						sx={{
							backgroundColor: "rgba(255,255,255,0.36)",
							position: "absolute",
							top: "50%",
							left: 0,
							transform: "translateY(-50%)",
							borderRadius: 31,
							zIndex: 3,
							height: 60,
							width: 60,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Arrow style={{ rotate: "90deg" }} width={50} height={50} />
					</Box>
					<Box
						onClick={handleNext}
						sx={{
							backgroundColor: "rgba(255,255,255,0.36)",
							position: "absolute",
							top: "50%",
							right: 0,
							transform: "translateY(-50%)",
							borderRadius: 31,
							zIndex: 3,
							height: 60,
							width: 60,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Arrow style={{ rotate: "-90deg" }} width={50} height={50} />
					</Box>
				</Box>
			)}
			{!isMobile && (
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					sx={{ flexWrap: "wrap", gap: 10 }}
					mt={10}
				>
					{teamExperts.map((expert) => (
						<HomeExpertCard expert={expert} />
					))}
					<ButtonPlus />
				</Box>
			)}
		</SectionWrapper>
	);
};

export default HomeExperts;

const ButtonPlus = () => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				height: 221,
				justifyContent: "flex-end",
				flexDirection: "column",
				visibility: 'hidden'
			}}
			onClick={() => window.open('https://my.inexplore.com/contact?topic=1', '_blank', 'noopener,noreferrer')}
		>
			<EventBox
				sx={{
					width: 125,
					height: 125,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "100%",
					backgroundColor: "rgba(255,255,255,0.05)",
					cursor: "pointer",
					"&::before": {
						borderRadius: "100%",
					},
				}}
			>
				<Plus />
			</EventBox>
			<Typography mt={2} fontFamily={"Branding SemiBold"} fontSize={15} color={"#FBF0E5"} textAlign={"center"}>
				Postuler pour faire partie de nos experts
			</Typography>
		</Box>
	);
};
