import React from "react";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../hooks/scrollToTop";

const MainLayout = ({ children }) => {
	return (
		<>
			<ScrollToTop />

			<Header />
			<Outlet />
			<Footer />
		</>
	);
};

export default MainLayout;
