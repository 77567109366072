import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Ellipse } from "resources/images/svg/home/expert-ellipse.svg";

const HomeExpertCard = ({ expert }) => {
	return (
		<Box sx={{ position: "relative", width: 193, height: 221 }}>
			<Box
				sx={{
					background: "linear-gradient(180deg, rgba(9, 1, 30, 0) 0%, #09011E 100%)",
					height: 162,
					width: "100%",
					position: "absolute",
					zIndex: 4,
					bottom: -1,
				}}
			/>
			<Ellipse
				style={{
					position: "absolute",
					bottom: 0,
					right: 0,
					zIndex: 0,
					width: 200,
					height: 200,
				}}
			/>
			<Box
				px={2}
				sx={{
					position: "absolute",
					bottom: 5,
					zIndex: 5,
				}}
			>
				<Typography color={"#FBF0E5"} fontFamily={"Branding SemiBold"} textAlign={"center"} fontSize={16}>
					{expert.prenom} {expert.nom}
				</Typography>
				<Typography
					color={"rgba(251,240,229,0.5)"}
					fontFamily={"Branding SemiBold"}
					fontSize={14}
					textAlign={"center"}
				>
					{expert.profession}
				</Typography>
			</Box>
			<img src={expert.png}  style={{ zIndex: 2, position: "relative", width: '100%' }} />
		</Box>
	);
};

export default HomeExpertCard;
