import React from "react";
import { Grid, Typography } from "@mui/material";
import natureBackground from "resources/images/home/nature-background.png";
import SectionWrapper from "../../layout/SectionWrapper";

const HomeInterest = () => {
	return (
		<SectionWrapper>
			<Grid container spacing={10}>
				<Grid item md={6} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
					<Typography fontFamily={"Yesevaone"} color={"#FBF0E5"} fontSize={40} lineHeight={1.3}>
						Nos actions d’intérêt général
					</Typography>
					<Typography
						mt={4}
						sx={{
							background: "linear-gradient(90deg, #992FDD 0%, #1D73FF 100%)",
							"-webkit-background-clip": "text",
							"-webkit-text-fill-color": "transparent",
						}}
						fontFamily={"Branding Bold"}
						fontSize={24}
					>
						POUR LES PARTICULIERS : UN ESPACE D’ÉCOUTE
					</Typography>
					<Typography mt={2} color={"#FBF0E5"} fontFamily={"Branding Medium"} fontSize={16}>
						Depuis les débuts, pour accueillir le récit et les témoignages des expériences extraordinaires 
						dans un cadre bienveillant, sécurisant et éthique, une plateforme de messagerie électronique offre 
						aux témoins la possibilité d’exprimer leur vécu et de trouver une écoute auprès des professionnels.
						<br />
						<br />
						Fortement sensibilisés à l’extraordinaire, ces professionnels offrent, par l’intermédiaire de leurs messages, 
						conseil et soutien à ceux qui en ont besoin et qui ne trouvent pas d’espace d’accueil.
						<br />
						<br />
						Par cette action unique et en mettant à profit leur formation clinique et thérapeutique, 
						ils participent ainsi à développer le cadre et les outils thérapeutiques, affinent la pratique 
						et les connaissances de toutes les expériences humaines extraordinaires qui reçoivent 
						très peu d’explications conventionnelles à ce jour.
					</Typography>
				</Grid>
				<Grid item md={6} xs={12} display={"flex"} justifyContent={"center"}>
					<img src={natureBackground} style={{ maxHeight: 400, maxWidth: "90%" }} />
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

export default HomeInterest;
