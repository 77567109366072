import { api } from "./rtkApi";
import { encode } from "querystring";

export const userApi = api.injectEndpoints({
	endpoints: (build) => ({
		updateNewsPro: build.mutation({
			query: ({ email, type }) => {
				return {
					method: "POST",
					url: `/connect/emailpro`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({email, type}),
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useUpdateNewsProMutation } = userApi;
