import React from "react";
import { Box } from "@mui/material";
import { useIsMobile } from "../hooks/responsive";

const MAX_WIDTH = 1300;
const PADDING = 6;
const MOBILE_PADDING = 4;

const SectionWrapper = ({ children, backgroundColor = "transparent" }) => {
	const isMobile = useIsMobile();
	const padding = isMobile ? MOBILE_PADDING : PADDING;
	return (
		<section style={{ backgroundColor }}>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Box sx={{ maxWidth: MAX_WIDTH, width: "100%" }} p={padding}>
					{children}
				</Box>
			</Box>
		</section>
	);
};

export default SectionWrapper;
