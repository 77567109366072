import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//Pages
import HomePage from "routes/Home";
import MainPage from "routes/Main";
import HolistiquePage from "routes/Holistique";

import MainLayout from "layout/MainLayout";
import { Provider } from "react-redux";
import { store } from "store/store";
import "resources/scss/main.scss";

let router = createBrowserRouter([
	{
		path: "/",
		element: <MainLayout />,
		children: [
			{
				index: true,
				element: <MainPage />,
			},
			{
				path: "Accueillir-extraordinaire",
				title: "Accueillir l’extraordinaire - Inexploré pro",
				element: <HomePage />,
			},
			{
				path: "Approche-holistique",
				element: <HolistiquePage />,
			},
		],
	},
]);
function App() {
	return (
		<Provider store={store}>
			<RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
		</Provider>
	);
}

export default App;
