import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { ReactComponent as NewsletterImage } from "resources/images/home/newsletter.svg";
import Input from "@mui/material/Input";
import useWindowDimensions from "../../hooks/useWindowDimension";
import SectionWrapper from "../../layout/SectionWrapper";
import { useIsMobile } from "../../hooks/responsive";
import { useUpdateNewsProMutation } from "store/api/user";
import DialogRegisterModal from "../common/dialog/DialogRegister";

const StyledInput = styled(Input)(() => {
	return {
		backgroundColor: "#FFFFFF",
		borderRadius: 30,
		width: 350,
		maxWidth: "70vw",
		padding: "10px 20px",
		"& .MuiFormLabel-root": {
			color: "#332E4580",
			fontFamily: "Branding Medium",
		},
	};
});

const HomeNewsLetter = () => {
	const [email, setEmail] = React.useState("");
	const [showInput, setShowInput] = React.useState(true);
	const [openModal, setOpenModal] = React.useState(false);

	const { width } = useWindowDimensions();
	const isMobile = useIsMobile();

	const [updateNewsPro] = useUpdateNewsProMutation();

	const { imgWidth, imgHeight } = React.useMemo(() => {
		const ratio = 735 / 376;
		const widthToFill = 2;
		if (!width) {
			return { imgWidth: 0, imgHeight: 0 };
		}
		let computedWidth = Math.min(2000, width);
		if (computedWidth < 600) {
			computedWidth = 600;
		}
		return { imgWidth: computedWidth / widthToFill, imgHeight: computedWidth / widthToFill / ratio };
	}, [width]);

	const handleSubmit = () => {
		const result = updateNewsPro({
			email,
			type: 2,
		})
		.then(dataresult => {
			if(!dataresult.error){
				setShowInput(!showInput);
			} else {
				setOpenModal(!openModal);
			}
		})
		.catch(err => {
			console.log('erreur', err);
		});
		
	}

	return (
		<SectionWrapper>
			<Box>
				<Typography
					color={"#FFFFFF"}
					fontFamily={"Branding Bold"}
					fontSize={isMobile ? 30 : 35}
					textAlign={"center"}
					mb={2}
					lineHeight={1.3}
				>
					Suivez ce réseau, inscrivez-vous à la lettre d’information 
				</Typography>
				<Typography
					color={"#8d36d5"}
					fontFamily={"Branding Bold"}
					fontSize={isMobile ? 30 : 35}
					textAlign={"center"}
					mb={2}
					lineHeight={1.3}
				>Vers une approche holistique
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
					<NewsletterImage sx={{ maxWidth: 200 }} width={imgWidth} height={imgHeight} />
					<Box
						display={showInput ? "flex" : "none"}
						flexWrap={"wrap"}
						justifyContent={"center"}
						sx={{ gap: 2, overflow: "hidden" }}
					>
						<StyledInput
							id="outlined-basic"
							placeholder="Votre adresse email"
							disableUnderline={true}
							onChange={(event) => {
								setEmail(event.target.value);
							}}
						/>
						<Button
							variant="contained"
							sx={{
								backgroundColor: "#2271FE",
								borderRadius: 10,
								color: "#FBF0E5",
								fontFamily: "Branding SemiBold",
								textTransform: "none",
								padding: "10px 20px",
							}}
							onClick={handleSubmit}
						>
							Je m’inscris
						</Button>
					</Box>
				</Box>
			</Box>
			<DialogRegisterModal
				open={openModal}
				onClose={() => {
					setOpenModal(!openModal);
				}}
			/>
		</SectionWrapper>
	);
};

export default HomeNewsLetter;
