import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import ImageHeader from "resources/images/home/headerEXTRA.jpg";
import { ReactComponent as Ellipse } from "resources/images/home/ellipse.svg";
import { useIsMobile } from "../../hooks/responsive";

const Tag = styled(Box)(() => ({
	borderWidth: 2,
	borderStyle: "dotted",
	borderColor: "grey",
	borderImageSource: "linear-gradient(90deg, #992FDD 0%, #1D73FF 100%)",
	borderImageSlice: 1,
	width: "fit-content",
	padding: "5px 20px",
}));

const LeftGradient = styled(Box)(() => {
	return {
		background:
			"linear-gradient(89.98deg, #0F0037 1.06%, #0F0037 69.92%, rgba(15, 0, 55, 0) 99.98%, rgba(15, 0, 55, 0.0) 99.98%)",

		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: "10%",
		zIndex: 1,
	};
});

const Background = styled(Box)(() => {
	return {
		background: "linear-gradient(62.24deg, #162A41 18.81%, #8F6C9F 135.41%)",
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: -1,
	};
});

const HomeIntro = () => {
	const isMobile = useIsMobile();

	return (
		<Box sx={{ position: "relative", width: "100%" }}>
			<Box px={4} py={4} sx={{ zIndex: 2, position: "relative" }}>
				<Typography fontFamily={"Yesevaone"} color={"#FBF0E5"} fontSize={40} mb={2} lineHeight={1.3}>
					Accueillir l’extraordinaire
				</Typography>
				<Tag mb={4}>
					<Typography fontFamily={"Branding Medium"} color={"#FBF0E5"}>Professionnels de santé </Typography>
				</Tag>
				<Typography fontFamily={"Branding Bold"} color={"#FBF0E5"} fontSize={35} mb={1}>
					Le manifeste
				</Typography>
				<Typography
					fontFamily={"Branding Medium"}
					fontSize={isMobile ? 15 : 17}
					color={"#FBF0E5"}
					sx={{ width: 800, maxWidth: isMobile ? "100%" : "50%" }}
					mb={3}
				>
					Le réseau des professionnels de santé a été créé dans la perspective de développer 
					une approche thérapeutique qui intègre et reçoit les expériences extraordinaires 
					de celui ou celle qui les vit. À contre-courant de l’approche parfois très sceptique 
					et critique exercée par un système conventionnel peu enclin à l’ouverture face à 
					des sujets encore méconnus, l’objectif est de construire un cadre clinique moral rigoureux, 
					bienveillant et sans jugement préétabli qui accueille, dans la pratique, ces expériences.
					<br />
					<br />
					Mais plus encore, l’ambition est sociétale : elle s’inscrit dans une démarche d’intérêt général 
					pour les témoins d’expériences et la profession. Alors que les expériences extraordinaires sont 
					de plus en plus rapportées, étudiées et leur évocation de moins en moins tabou, intégrer leur prise 
					en charge au sein d’un cadre clinique sécurisant et éthique devient primordial pour les consultants. 
					Dans la perspective de rapprocher de manière scientifique et rigoureuse le monde visible du monde invisible, 
					la sensibilisation et la formation des professionnels en demande, le partage et le développement 
					des connaissances encore limitées sur ces sujets deviennent, elles aussi, une nécessité.
					<br />
					<br />
					Le réseau est composé de deux cercles :
					<br />
					- un groupe élargi de professionnels motivés par nos services, inscrits à notre lettre d’informations, 
					participant également à nos supervisions,
					<br />
					- un noyau dur, une équipe resserrée composée de référents, psychologues et psychothérapeutes sensibilisés 
					et spécialisés dans l'accompagnement à l’extraordinaire, actifs dans le développement de nos actions d’intérêts général.
					Tous sont désireux de mettre en commun leurs observations et leurs connaissances éprouvées pour rendre possible 
					un meilleur accompagnement, poser les fondamentaux de l’approche des expériences extraordinaires, s’entraider 
					et sensibiliser d’autres professionnels de santé à ces vécus.

				</Typography>
			</Box>
			<Background />
			{!isMobile && <LeftGradient />}

			<img
				src={ImageHeader}
				style={{
					position: "absolute",
					top: 0,
					bottom: 0,
					height: "100%",
					right: 0,
					opacity: 0.32,
				}}
			/>
			{!isMobile && (
				<Ellipse
					style={{
						position: "absolute",
						top: 0,
						zIndex: 3,
						right: 0,
						height: "90%",
						transform: "translateY(5%)",
					}}
				/>
			)}
		</Box>
	);
};

export default HomeIntro;
