import React from "react";
import { Grid, styled } from "@mui/material";

const Burger = styled("li")(({ theme }) => {
	return {
		width: "1.4rem",
		height: "0.95rem",
		padding: 0,
		margin: "0 0.475rem",
		cursor: "pointer",
		position: "relative",
		listStyleType: "none",
		".slice": {
			width: "100%",
			height: "0.1rem",
			background: "#fbf0e5",
			position: "absolute",
			left: 0,
			zIndex: 51,
			opacity: 1,
			transition: " all 0.3s ease-out",
			"&:nth-child(1)": {
				top: 0,
				transform: "rotate(0deg) translate(0px, 0px)",
			},
			"&:nth-child(2)": {
				top: "47%",
			},
			"&:nth-child(3)": {
				bottom: 0,
				transform: "rotate(0deg) translate(0px, 0px)",
			},
		},
		"&.burger-open": {
			".slice": {
				"&:nth-child(1)": {
					transform: "rotate(45deg) translate(3px, 5px)",
				},
				"&:nth-child(2)": {
					opacity: 0,
				},
				"&:nth-child(3)": {
					transform: "rotate(-45deg) translate(5px, -7px)",
				},
			},
		},
	};
});

const BurgerButton = ({ isOpen, setIsOpen }) => {
	React.useEffect(() => {
		const element = document.getElementById("root");
		if (isOpen) {
			element.style.height = "100vh";
			element.style.overflowY = "hidden";
		} else {
			element.style.height = "100%";
			element.style.overflowY = "auto";
		}
	}, [isOpen]);

	return (
		<Burger onClick={() => setIsOpen(!isOpen)} className={`burger ${!!isOpen && "burger-open"}`}>
			<div className="slice"></div>
			<div className="slice"></div>
			<div className="slice"></div>
		</Burger>
	);
};

export default BurgerButton;
