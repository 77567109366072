import React from "react";
import { Divider, Grid, Stack, styled, Box } from "@mui/material";
import { ReactComponent as InexploreProLogo } from "resources/images/svg/common/inexplore_pro.svg";
import { ReactComponent as Compass } from "resources/images/svg/common/compass.svg";
import { ReactComponent as Search } from "resources/images/svg/common/search.svg";
import { ReactComponent as User } from "resources/images/svg/common/user.svg";
import Universe from "./Universe";
import { useNavigate } from "react-router-dom";
import MobileHeader from "./MobileHeader";
import { useIsMobile } from "../../hooks/responsive";

const HeaderContainer = styled(Box)(() => {
	return {
		height: 90,
		background: "linear-gradient(90deg, #09011E 0%, #992FDD 74.9%, #1D73FF 100%)",
		alignItems: "center",
		px: 2,
		padding: "0 30px",
		position: "fixed",
		zIndex: 50,
		width: "100%",
		display: "flex",
		justifyContent: "center",
	};
});

const Wrapper = styled(Grid)(() => {
	return {
		maxWidth: 1500,
		justifyContent: "space-between",
		alignItems: "center",
	};
});

const Header = () => {
	const navigate = useNavigate();

	const navigateToHome = () => {
		navigate("/");
	};

	const isMobile = useIsMobile();

	if (isMobile) {
		return <MobileHeader />;
	}

	return (
		<Box>
			<HeaderContainer>
				<Wrapper container>
					<Grid item sx={{ cursor: "pointer" }} onClick={navigateToHome}>
						<InexploreProLogo />
					</Grid>
					<Stack direction="row" spacing={2} alignItems={"center"}>
						<Box sx={{ marginRight: "76.8px !important" }}>
							<Universe />
						</Box>
						{/*<Divider*/}
						{/*	orientation="vertical"*/}
						{/*	flexItem*/}
						{/*	sx={{ backgroundColor: "rgba(251,240,229,0.3)", height: 60 }}*/}
						{/*/>*/}
						<Search />
						<Compass />
						<User />
					</Stack>
				</Wrapper>
			</HeaderContainer>
			<Box sx={{ height: 90 }} />
		</Box>
	);
};

export default Header;
