import React from "react";
import { Box, Grid, Stack, styled, Typography } from "@mui/material";

import { ReactComponent as Compass } from "resources/images/svg/common/compass.svg";
import { ReactComponent as Search } from "resources/images/svg/common/search.svg";
import { ReactComponent as User } from "resources/images/svg/common/user.svg";

const MenuItemLink = styled("a")(() => {
	return {
		fontFamily: "Yesevaone",
		color: "#fff",
		fontSize: 25,
		cursor: "pointer",
	};
});
const Divider = styled(Box)(() => {
	return {
		backgroundColor: "#c7c4de",
		width: "100%",
		height: "2px",
	};
});

const ItemTypo = styled("a")(() => {
	return {
		fontFamily: "Branding SemiBold",
		color: "#fff",
		fontSize: 16,
	};
});

const UserContainer = styled(Grid)(({ theme }) => {
	return {
		width: "25px",
		height: "25px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "25px",
		backgroundColor: "rgba(36,38,56,.53)",
		"&:hover": {
			cursor: "pointer",
		},
	};
});

const UserTypography = styled(Typography)(({ theme }) => {
	return {
		color: "white",
		fontFamily: "Yesevaone",
		fontSize: "12px",
		pointerEvents: "none",
	};
});

const ItemContainer = styled(Box)(() => {
	return {
		display: "flex",
		gap: 15,
		alignItems: "center",
		cursor: "pointer",
	};
});

const LeftItemContainer = styled(Box)(() => {
	return {
		width: 30,
		display: "flex",
		justifyContent: "center",
	};
});

const MenuPanelContent = ({ redirect }) => {
	// const user = useSelector(getUser);

	return (
		<Stack mx={"20px"} spacing={4}>
			<Stack spacing={2}>
				<ItemContainer onClick={() => redirect("/")}>
					<LeftItemContainer>
						<UserContainer onClick={(event) => {}}>
							<UserTypography></UserTypography>
						</UserContainer>
					</LeftItemContainer>
					<ItemTypo>Mon profil</ItemTypo>
				</ItemContainer>
				<ItemContainer onClick={() => redirect("/recherche")}>
					<LeftItemContainer>
						<Search width={24} height={24} />
					</LeftItemContainer>
					<ItemTypo>Recherche</ItemTypo>
				</ItemContainer>
				<ItemContainer onClick={() => redirect("/favoris")}>
					<LeftItemContainer>
						<Compass width={30} height={30} />
					</LeftItemContainer>
					<ItemTypo>Favoris</ItemTypo>
				</ItemContainer>
			</Stack>
			{/*<Divider />*/}
		</Stack>
	);
};

export default MenuPanelContent;
