import React from "react";
import MainHeader from "../components/mainPage/MainHeader";
import { Box } from "@mui/material";
import MainAboutUs from "../components/mainPage/MainAboutUs";

const MainPage = () => {
	return (
		<Box sx={{ backgroundColor: "#09011E" }}>
			<MainHeader />
			<MainAboutUs />
		</Box>
	);
};

export default MainPage;
