import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import ImageHeader from "resources/images/home/headerHOLISTIQUE.jpg";
import { ReactComponent as Ellipse } from "resources/images/home/ellipse.svg";
import { useIsMobile } from "../../hooks/responsive";

const Tag = styled(Box)(() => ({
	borderWidth: 2,
	borderStyle: "dotted",
	borderColor: "grey",
	borderImageSource: "linear-gradient(90deg, #992FDD 0%, #1D73FF 100%)",
	borderImageSlice: 1,
	width: "fit-content",
	padding: "5px 20px",
}));

const LeftGradient = styled(Box)(() => {
	return {
		background:
			"linear-gradient(89.98deg, #0F0037 1.06%, #0F0037 69.92%, rgba(15, 0, 55, 0) 99.98%, rgba(15, 0, 55, 0.0) 99.98%)",

		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: "10%",
		zIndex: 1,
	};
});

const Background = styled(Box)(() => {
	return {
		background: "linear-gradient(62.24deg, #162A41 18.81%, #8F6C9F 135.41%)",
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: -1,
	};
});

const HomeIntro = () => {
	const isMobile = useIsMobile();

	return (
		<Box sx={{ position: "relative", width: "100%" }}>
			<Box px={4} py={4} sx={{ zIndex: 2, position: "relative" }}>
				<Typography fontFamily={"Yesevaone"} color={"#FBF0E5"} fontSize={40} mb={2} lineHeight={1.3}>
				Vers une approche holistique
				</Typography>
				<Tag mb={4}>
					<Typography fontFamily={"Branding Medium"} color={"#FBF0E5"}>Praticiens & soignants </Typography>
				</Tag>
				<Typography fontFamily={"Branding Bold"} color={"#FBF0E5"} fontSize={35} mb={1}>
					Le manifeste
				</Typography>
				<Typography
					fontFamily={"Branding Medium"}
					fontSize={isMobile ? 15 : 17}
					color={"#FBF0E5"}
					sx={{ width: 800, maxWidth: isMobile ? "100%" : "50%" }}
					mb={3}
				>
					Ce réseau a été fondé sur la conviction que la réalité humaine transcende les apparences et que chaque 
					individu vit une expérience que nous pouvons qualifier de « multidimensionnelle ». 
					Entre visible et invisible, au cœur de l’extraordinaire, les soins intégratifs, 
					en tant qu'approche holistique, visent à promouvoir le bien-être global des individus en respect 
					et complément de la médecine conventionnelle. Ils ouvrent de nouvelles perspectives pour l’harmonie du corps et de l’esprit !
					<br />
					<br />
					Nos objectifs pour les professionnels sont les suivants :
					<br />
					<br />
					<b>- Fonder un réseau solidaire de praticiens</b> : 
					développer une communauté forte où les praticiens peuvent se soutenir et partager leurs vécus, réussites et défis.
					<br />
					<b>- Créer des ponts entre les praticiens de disciplines différentes</b> : favoriser la collaboration et l’échange pour une meilleure approche intégrative.
					<br />
					<b>- Travailler sur le cadre éthique de ces approches</b> : engager les professionnels afin d’éclairer les fondamentaux et offrir plus transparence au public.
					<br />
					<b>- Partager des informations clés aux praticiens</b> : offrir des ressources essentielles et des connaissances actualisées pour améliorer le quotidien des professionnels.
					<br />
					<br />
					Plus largement, notre réseau s'inscrit dans un projet d'intérêt général portant ces autres buts :
					<br />
					<b>- Sensibiliser autour de ces pratiques</b> : informer le public sur les enjeux de ces approches pour promouvoir compréhension et acceptation.
					<br />
					<b>- Faciliter le dialogue auprès des institutions</b> : favoriser une forme de coopération à la croisée des mondes, afin d’élaborer des politiques plus inclusives.
				</Typography>
				
			</Box>
			<Background />
			{!isMobile && <LeftGradient />}

			<img
				src={ImageHeader}
				style={{
					position: "absolute",
					top: 0,
					bottom: 0,
					height: "100%",
					right: 0,
					opacity: 0.32,
				}}
			/>
			{!isMobile && (
				<Ellipse
					style={{
						position: "absolute",
						top: 0,
						zIndex: 3,
						right: 0,
						height: "90%",
						transform: "translateY(5%)",
					}}
				/>
			)}
		</Box>
	);
};

export default HomeIntro;
