import React from "react";
import HomeIntro from "../components/homePage/HomeIntro";
import { Box } from "@mui/material";
import HomeNewsLetter from "../components/homePage/HomeNewsLetter";
import HomeActionsPro from "../components/homePage/HomeActionsPro";
import HomeExperts from "../components/homePage/HomeExperts";
import HomeInterest from "../components/homePage/HomeInterest";
import useTabTitle from "../hooks/useTabTitle";

const Home = () => {
	useTabTitle({ customTitle: "Accueillir l’extraordinaire - Inexploré pro" });
	return (
		<Box sx={{ backgroundColor: "#09011E" }}>
			<HomeIntro />
			<HomeNewsLetter />
			<HomeActionsPro />
			<HomeExperts />
			<HomeInterest />
		</Box>
	);
};

export default Home;
