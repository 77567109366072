import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ReactComponent as Floral } from "resources/images/svg/main/floral.svg";
import { Link } from "react-router-dom";

const ABOUT = [
	{
		title: "Notre histoire",
		link: "https://apropos.inexplore.com/notre-histoire",
		description:
			"Inexploré est un média à 360° qui invite chacun à découvrir l’extraordinaire et à explorer les frontières entre science et spiritualité pour enrichir nos connaissances intérieures…",
	},
	{
		title: "Qui sommes-nous ?",
		link: "https://apropos.inexplore.com/qui-sommes-nous",
		description:
			"Stéphane Allix et Sébastien Lilli, marqués par des expériences personnelles profondes, ont fondé l'INREES et Inexploré. Ils sont aujourd’hui entouré d’une équipe d’explorateurs de la conscience…",
	},
	{
		title: "Nos valeurs",
		link: "https://apropos.inexplore.com/nos-valeurs",
		description:
			"L’INREES, qui édite Inexploré, est une entreprise qui prône le respect du vivant et de l’être humain et qui s’engage en faveur de l’évolution des consciences…",
	},
];
const openInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

const MainAboutUs = () => {
	return (
		<Box
			sx={{
				background: "linear-gradient(90deg, #21639E 0%, #6C3490 100%)",
				position: "relative",
				overflow: "hidden",
			}}
			p={6}
		>
			<Box sx={{ position: "relative", zIndex: 2 }}>
				<Typography textAlign={"center"} fontFamily={"Yesevaone"} fontSize={40} color={"#FBF0E5"} mb={4}>
					À propos de nous
				</Typography>
				<Grid container spacing={4} justifyContent={"center"}>
					{ABOUT.map((item) => (
						<Grid item md={3.5} xs={12}>
							<Box
								sx={{
									backgroundColor: "#1B1843",
									borderRadius: "20px",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}
								p={4}
							>
								<Typography
									fontFamily={"Yesevaone"}
									color={"#FBF0E5"}
									fontSize={30}
									lineHeight={1.2}
									mb={2}
								>
									{item.title}
								</Typography>
								<Typography fontFamily={"Branding Medium"} color={"#FBF0E5"} mb={2}>
									{item.description}
								</Typography>
								<Button
									variant="contained"
									sx={{
										backgroundColor: "#FBF0E5",
										borderRadius: 10,
										color: "#182B43",
										fontFamily: "Branding SemiBold",
										textTransform: "none",
										padding: "10px 20px",
										width: "100%",
									}}
									onClick={() => openInNewTab(item.link)}
									title="En savoir plus sur ce groupe"
								>
									En savoir plus
								</Button>
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>
			<Floral
				style={{
					position: "absolute",
					top: 0,
					zIndex: 1,
					left: 0,
					bottom: 0,
					opacity: 0.4,
					overflow: "hidden",
					right: 0,
				}}
			/>
		</Box>
	);
};

export default MainAboutUs;
