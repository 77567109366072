import React from "react";
import { Divider, Grid, Stack, styled, Box } from "@mui/material";
import { ReactComponent as InexploreProLogo } from "resources/images/svg/common/inexplore_pro.svg";
import Universe from "./Universe";
import { useNavigate } from "react-router-dom";
import BurgerButton from "./BurgerButton";
import MenuPanelContent from "./MenuPanelContent";

const HeaderContainer = styled(Box)(() => {
	return {
		height: 90,
		background: "linear-gradient(90deg, #09011E 0%, #992FDD 74.9%, #1D73FF 100%)",
		alignItems: "center",
		px: 2,
		padding: "0 30px",
		position: "fixed",
		zIndex: 50,
		width: "100%",
		display: "flex",
		justifyContent: "center",
	};
});

const MenuPanel = styled(Box)(() => {
	return {
		position: "fixed",
		height: "calc(100% - 2.55rem)",
		width: "100%",
		background: "linear-gradient(90deg, #09011E 0%, #992FDD 74.9%, #1D73FF 100%)",

		top: "90px",
		transition: "left 0.3s ease-out",
		left: "-100%",
		"&.isOpen": {
			left: 0,
		},
	};
});

const Wrapper = styled(Grid)(() => {
	return {
		justifyContent: "space-between",
		alignItems: "center",
	};
});

const MobileHeader = () => {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = React.useState(false);

	const navigateToHome = () => {
		navigate("/");
	};

	return (
		<Box>
			<HeaderContainer>
				<Wrapper container>
					<Grid item>
						<Universe isMobile={true} />
					</Grid>
					<Grid item sx={{ cursor: "pointer" }} onClick={navigateToHome}>
						<InexploreProLogo />
					</Grid>
					<Grid item>
						<Grid item sx={{}}>
							<BurgerButton isOpen={isOpen} setIsOpen={setIsOpen} />
						</Grid>
						<MenuPanel className={`${!!isOpen ? "isOpen" : "closed"}`}>
							<MenuPanelContent redirect={() => null} />
						</MenuPanel>
					</Grid>
				</Wrapper>
			</HeaderContainer>
			<Box sx={{ height: 90 }} />
		</Box>
	);
};

export default MobileHeader;
